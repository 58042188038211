export const castingDirectorDefaultQuickLinks = [
  {
    text: "common:nav.links.createBreakdown",
    href: "/casting/create-breakdown",
  },
  {
    text: "common:nav.links.draftBreakdowns",
    href: "/casting/active-breakdowns",
  },
  {
    text: "common:nav.links.projectFolders",
    href: "/casting/projects",
  },
  {
    text: "common:nav.links.performerSearch",
    href: "/casting/performers",
  },
  {
    text: "common:nav.links.myAgentsList",
    href: "/casting/agent-lists",
  },
  {
    text: "common:nav.links.myShortlists",
    href: "/casting/shortlists",
  },
  {
    text: "common:nav.links.contacts",
    href: "https://www.spotlight.com/contacts",
  },
  {
    text: "common:nav.links.industryForumArchive",
    href: "https://www.spotlight.com/the-small-print/industry-forum-2022-highlights/",
  },
  {
    text: "common:nav.links.digitalShowcase",
    href: "https://spotlightshowcase.co/graduating-schools-2021/",
  },
  {
    text: "common:nav.links.proFile",
    href: "https://profileperformers.com/watch-profiles/",
  },
  {
    text: "common:nav.links.performanceCalendar",
    href: "https://calendar.spotlight.com/",
  },
];

export const castingDirectorSearchOnlyQuickLinks = [
  {
    text: "common:nav.links.createJob",
    href: "https://spotlightuk.typeform.com/to/z7dZxceX",
  },
  {
    text: "common:nav.links.performerSearch",
    href: "/casting/performers",
  },
  {
    text: "common:nav.links.myShortlists",
    href: "/casting/shortlists",
  },
  {
    text: "common:nav.links.contacts",
    href: "/contacts",
  },
  {
    text: "common:nav.links.industryResources",
    href: "https://www.spotlight.com/help-and-faqs/links-for-professionals-on-spotlight",
  },
  {
    text: "common:nav.links.proFile",
    href: "https://profileperformers.com/watch-profiles/",
  },
];

export const castingDirectorInactiveQuickLinks = [
  {
    text: "common:nav.links.contacts",
    href: "https://www.spotlight.com/contacts",
  },
  {
    text: "common:nav.links.industryResources",
    href: "https://www.spotlight.com/help-and-faqs/links-for-professionals-on-spotlight",
  },
];
