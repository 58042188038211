import React, { FC, Fragment, useContext, useEffect } from "react";
import { Trans, useTranslation } from "i18n";
import cx from "classnames";
import { parseUrl } from "query-string";
import { useDynamicWindowWidthFor, useScrollToElement } from "shared-hooks";
import SubscriptionCard from "../SubscriptionCard";
import {
  CurrencyType,
  IAddOns,
  ISubscription,
  PlanCodePeriod,
  planPeriodMap,
} from "../../../types";
import { SCREEN_WIDTH_ENUM } from "../../../../../../generics/dom-extensions";
import FeatureBox from "../FeatureBox";
import {
  BASE_ANNUAL_TEXTS,
  BASE_MONTHLY_TEXTS,
  getAddOnTexts,
  injectPlanButtonLabel,
  features,
} from "./PlanSelector.helpers";
import { BillingContext } from "../../../BillingContext";
import { FEATURE_FLAGS, useLaunchDarklyFlags } from "shared-services";

interface IPlanSelectorProps {
  selectedPeriod: PlanCodePeriod | undefined;
  setSelectedPeriod: (plan: PlanCodePeriod) => void;
  setIsAddOnSelected: (addOn: IAddOns) => void;
  subscriptions: (ISubscription | undefined)[];
  currency?: CurrencyType;
}

const PlanSelector: FC<IPlanSelectorProps> = ({
  selectedPeriod,
  setSelectedPeriod,
  setIsAddOnSelected,
  currency = CurrencyType.GBP,
  subscriptions,
}) => {
  const { ref, scrollToElement } = useScrollToElement({
    block: "nearest",
    inline: "start",
  });
  const isMobileView = useDynamicWindowWidthFor(SCREEN_WIDTH_ENUM.SM);
  const { planPeriodData } = useContext(BillingContext);
  const { t } = useTranslation();
  const isECPModalVariantFlagActive = useLaunchDarklyFlags(
    FEATURE_FLAGS.ECPModalVariant
  );

  const isAnnualSelected = selectedPeriod === PlanCodePeriod.Annual;

  const selectedPeriodAddOns = subscriptions.find(
    (sub) => selectedPeriod && sub?.code.includes(selectedPeriod)
  )?.addOns;

  const isAddOnSelected = (addOn: IAddOns) => {
    return planPeriodData.selectedAddOns.some((obj) => obj.code === addOn.code);
  };

  useEffect(() => {
    selectedPeriod && !isECPModalVariantFlagActive && scrollToElement();
  }, [selectedPeriod]);

  const location = window.location; // can't use useLocation here because some apps use v5 and some use v6
  const parsedUrl = parseUrl(location.search);
  const preSelectedPlan = parsedUrl.query.preSelectedPlan;

  const annualTexts =
    preSelectedPlan === PlanCodePeriod.Annual
      ? injectPlanButtonLabel(BASE_ANNUAL_TEXTS)
      : BASE_ANNUAL_TEXTS;
  const monthlyTexts =
    preSelectedPlan === PlanCodePeriod.Monthly
      ? injectPlanButtonLabel(BASE_MONTHLY_TEXTS)
      : BASE_MONTHLY_TEXTS;

  const showPlanSelectorLabel =
    subscriptions.length > 1 && subscriptions.every(Boolean);
  const cleanedSubscriptions = subscriptions.filter(Boolean);

  return (
    <div className="flex flex-col justify-center w-full gap-4 my-0 lg:mx-auto">
      {showPlanSelectorLabel && (
        <p className="text-lg font-semibold">
          {t("common:billing.addOn.planSelector.label")}
        </p>
      )}
      <div
        className={cx("flex gap-2", {
          "flex-col items-stretch": isMobileView,
          "items-center justify-center": !isMobileView,
        })}
      >
        {cleanedSubscriptions.map((subscription, i) => {
          if (!subscription) return null;

          const isAnnualPlan = subscription.code.includes(
            PlanCodePeriod.Annual
          );
          const isLastItem = i === cleanedSubscriptions.length - 1;
          const planPeriod = planPeriodMap[subscription.code];
          const isSelected =
            !!selectedPeriod && !!planPeriod && selectedPeriod === planPeriod;

          return (
            <Fragment key={subscription.code}>
              <SubscriptionCard
                isSelected={isSelected}
                onSelect={() => setSelectedPeriod(planPeriod)}
                price={subscription.amountValue}
                pricePlusVat={subscription.totalValue}
                planPeriod={planPeriod}
                currency={subscription.currency}
                texts={isAnnualPlan ? annualTexts : monthlyTexts}
                showBadge={isAnnualPlan}
              />
              {!isMobileView && !isLastItem && (
                <p className="text-xl font-semibold uppercase">
                  {t("common:label.or")}
                </p>
              )}
            </Fragment>
          );
        })}
      </div>
      {!!selectedPeriod &&
        selectedPeriodAddOns &&
        !isECPModalVariantFlagActive &&
        selectedPeriodAddOns?.length > 0 && (
          <div
            className="px-2 pb-2 rounded-lg bg-gradient-to-br from-color-brand-two to-color-brand-one"
            ref={ref}
          >
            <p className="my-2 text-xl text-white">
              {t("common:label.additionalServices")}
            </p>
            {selectedPeriodAddOns?.map((addOn) => {
              const feature = features[addOn.code];

              return (
                <div key={addOn.code}>
                  <FeatureBox
                    key={addOn.code}
                    texts={{
                      ...getAddOnTexts(addOn.code),
                      period: isAnnualSelected
                        ? "common:billing.planSelector.addOns.price.annual"
                        : "common:billing.planSelector.addOns.price.monthly",
                    }}
                    featurePrice={addOn.totalValue}
                    vatPrice={addOn.amount}
                    selectFeature={() => setIsAddOnSelected(addOn)}
                    isFeatureSelected={isAddOnSelected(addOn)}
                    features={feature.mainFeatures}
                    subFeatures={feature.subFeatures}
                    currency={currency}
                  />
                  {isECPModalVariantFlagActive && (
                    <p className="text-sm">
                      <Trans
                        i18nKey={getAddOnTexts(addOn.code).description}
                        components={{
                          link1: (
                            <a
                              href={getAddOnTexts(addOn.code).descriptionLink}
                              target="_blank"
                            />
                          ),
                        }}
                      />
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        )}
    </div>
  );
};

export default PlanSelector;
