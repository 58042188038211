// TODO in SL-5404: Rename this and make it the step 5 component

import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";
import cx from "classnames";
import { useTranslation } from "i18n";
import { useHistory } from "react-router-dom";
import { useRecurly } from "@recurly/react-recurly";
import { RecurlyError } from "@recurly/recurly-js/lib/error";
import { TokenPayload } from "@recurly/recurly-js/lib/token";
import {
  useDynamicWindowWidthFor,
  useExternalScript,
  useMobileKeyboardVisibility,
  useScrollToElement,
} from "shared-hooks";
import { FEATURE_FLAGS, useLaunchDarklyFlags } from "shared-services";
import SummaryBlock from "../SummaryBlock";
import checkPaymentMethod from "../../utils/checkPaymentMethod";
import DirectDebitConfirmPage from "../DirectDebitCofirmPage";
import {
  directDebitInitialValues,
  DISABILITY_DISCOUNT_CODE_ACC_LEVEL,
  FieldNames,
  PlanType,
  RECURLY_JS_URL,
} from "../../constants";
import { Spinner } from "../../../../Atoms/Spinner/Spinner";
import { BillingUser, billingUserMap } from "translations/src/models/billing";
import StickyCTABar from "../../../StickyCTABar";
import {
  CheckoutFlow,
  CommonErrorPopup,
  SCREEN_WIDTH_ENUM,
} from "../../../../../index";
import { billingService } from "../../services";
import usePreventLeaveBillingPage from "../../hooks/usePreventLeaveBillingPage";
import { useBillingTranslations } from "../../hooks/useBillingTranslations";
import { BillingContext } from "../../BillingContext";
import { useGetSubscriptions } from "../../hooks/useGetSubscriptions";
import { useGetSummary } from "../../hooks/useGetSummary";
import { useCpaOptIn } from "../../hooks/useCpaOptIn";
import {
  CurrencyType,
  ISubscription,
  ISummary,
  ITokenPayload,
  PlanCodeMethod,
  PlanCodePeriod,
} from "../../types";
import useBillingFormValues from "../../hooks/useBillingFormValues";
import useBillingFormValidation from "../../hooks/useBillingFormValidation";
import getInvalidCardFields from "../../utils/getInvalidCardFields";
import ThreeDSecurePage from "../ThreeDSecurePage";
import useCouponCodes from "../../hooks/useCouponCode";
import useReinitializeCardForm from "../../hooks/useReinitializeCardForm";
import RecurlyWrapper from "../RecurlyWrapper";
import { getPurchaseType } from "../../utils/getPurchaseType";
import generateBillingInfo from "../../utils/generateBillinginfo";
import { PageTitle } from "../../../../../../../../apps/joining-pages/src/components/layout/PageTitle";
import BillingAndPaymentForm from "../BillingAndPaymentForm";
import { PaymentErrorModal } from "../PaymentErrorModal";
import PriceInfo from "./PriceInfo";

const TOOLTIP_CONTAINER = "TOOLTIP_CONTAINER";

interface Props {
  subscriptions: ISubscription[];
  placeholderSubscription?: ISubscription;
  summary: ISummary;
}

const getCouponCodeForPurchase = (
  coupons: string[],
  hasAccountDiscount: boolean,
  shouldReturnArray: boolean
): string | string[] => {
  const hasAccountDiscountAndCoupon = hasAccountDiscount && coupons.length > 1;

  if (hasAccountDiscountAndCoupon) {
    const couponsLessAccountDiscount = coupons.filter(
      (coupon) => coupon !== DISABILITY_DISCOUNT_CODE_ACC_LEVEL
    );
    return shouldReturnArray
      ? couponsLessAccountDiscount
      : couponsLessAccountDiscount[0];
  }

  if (hasAccountDiscount) {
    return shouldReturnArray ? [] : "";
  }

  return shouldReturnArray ? coupons : coupons[0];
};

const AddOnCheckout: FC<Props> = ({
  subscriptions,
  placeholderSubscription,
  summary,
}) => {
  const { t } = useTranslation();
  const {
    postCheckoutCallback,
    successCallback,
    goBack,
    goBackText,
    checkoutText,
    navigateToCompletePage,
    planTypeFilter,
    useExistingPaymentMethod,
    checkoutFlow,
    billingUserType,
    planPeriodData,
    setPlanPeriodData,
  } = useContext(BillingContext);
  const isMobileView = useDynamicWindowWidthFor(SCREEN_WIDTH_ENUM.SM);
  const mobileKeyboardIsOpen = useMobileKeyboardVisibility(isMobileView);
  const [plan, setPlan] = useState<ISubscription | undefined>(
    placeholderSubscription
  );
  const [isFailed, setIsFailed] = useState<boolean>();
  const [recurlyErrorMsg, setRecurlyErrorMsg] = useState("");
  const [isDirectDebitPreview, setIsDirectDebitPreview] = useState<boolean>();
  const [formSubmissionState, setFormSubmissionState] = useState<{
    isSubmitted: boolean;
    isSubmitting: boolean;
  }>({
    isSubmitted: false,
    isSubmitting: false,
  });
  const [recurlyToken, setRecurlyToken] = useState<string>("");
  const [threeDSToken, setThreeDSToken] = useState<string>("");
  const [cardDetailError, setCardDetailError] = useState<
    Record<string, string>
  >({});
  const { mutate: optInCpa } = useCpaOptIn();
  const isAnnual = planPeriodData.selectedPeriod === PlanCodePeriod.Annual;
  const [paymentMethod, setPaymentMethod] = useState<PlanCodeMethod>();
  const [isFormOpen, setIsFormOpen] = useState(
    !!placeholderSubscription && !!paymentMethod
  );
  const currencySymbol = placeholderSubscription?.currencySymbol;
  const htmlFormRef = useRef<HTMLFormElement>(null);
  const recurly = useRecurly();
  const setPreventLeave = usePreventLeaveBillingPage();
  const {
    texts: {
      common,
      billingForm,
      paymentDetailForm,
      directDebitForm,
      validation,
    },
  } = useBillingTranslations(BillingUser.Common);
  const isECPModalVariantFlagActive = useLaunchDarklyFlags(
    FEATURE_FLAGS.ECPModalVariant
  );

  const { formValues, setFieldValue, setMultipleFields } =
    useBillingFormValues();
  const isDirectDebit = checkPaymentMethod(
    PlanCodeMethod.DirectDebit,
    plan?.code
  );
  const isSepa = plan?.planType === PlanType.Sepa;
  const isCC = plan?.planType === PlanType.Card;
  const validationMessages = useBillingFormValidation(
    formValues,
    { ...billingForm, ...directDebitForm },
    validation,
    plan?.planType
  );

  const {
    price,
    userCouponCodesObject,
    userCouponCodesStrings,
    hasAccountDiscount,
    couponErrors,
    setCoupon,
    removeCoupon,
    isCheckoutPreviewRequestLoading,
    refetchPrices,
  } = useCouponCodes(
    placeholderSubscription?.id,
    summary.discounts,
    planPeriodData.selectedAddOns
  );
  const { cardFormIsVisible, reinitializeForm } = useReinitializeCardForm();
  const { ref: sectionRef, scrollToElement: scrollToFormSection } =
    useScrollToElement({ block: "start", inline: "start" });
  const history = useHistory();
  // implemented renewal call logic directly because useMutation was messing with billingService's context.
  const [isRenewalLoading, setIsRenewalLoading] = useState(false);
  const [renewalError, setRenewalError] = useState("");

  useEffect(() => {
    if (planTypeFilter) {
      setPlan(undefined);
      setIsFormOpen(false);
    }
  }, [planTypeFilter]);

  const couponBag = {
    hasAccountDiscount,
    userCouponCodesObject,
    setCoupon,
    removeCoupon,
    couponErrors,
    isCheckoutPreviewRequestLoading,
  };

  const formErrors = {
    ...(formSubmissionState.isSubmitted ? validationMessages : {}),
    ...cardDetailError,
  };

  const formWrapperClassName = cx("flex justify-between mb-10", {
    "flex-row-reverse flex-1": !isMobileView,
    "flex-col": isMobileView,
  });

  const genericErrorMsg = t("common:billing.genericErrorMessage");

  const failurePurchaseAction = useCallback(
    (err?: string) => {
      setThreeDSToken("");
      setIsFailed(true);
      setRecurlyErrorMsg(err || genericErrorMsg);
      setFormSubmissionState({
        isSubmitted: true,
        isSubmitting: false,
      });
      reinitializeForm();
    },
    [setThreeDSToken, setIsFailed, reinitializeForm]
  );

  const addOnsCodes = planPeriodData.selectedAddOns.map(({ code }) => code);

  const trackingData = {
    memberType: billingUserType,
    purchaseType: getPurchaseType(checkoutFlow),
    paymentPeriod: plan?.code,
    paymentValue: plan?.amount,
    discountValue: price?.discount,
    promoCode: userCouponCodesStrings,
    addOns: addOnsCodes,
  };

  const purchase = async (token: string, threeDsToken?: string) => {
    try {
      const basePayload = {
        planId: plan?.id || "",
        couponCodes: getCouponCodeForPurchase(
          userCouponCodesStrings,
          hasAccountDiscount,
          true
        ) as string[],
        addOns: addOnsCodes,
      };
      const result = await billingService.purchase({
        token,
        ...(threeDsToken && { threeDsToken }),
        ...basePayload,
        // @ts-ignore
        planCurrency: plan?.currency as CurrencyType,
      });

      if (result?.data?.RecurlyError || result?.RecurlyError) {
        failurePurchaseAction(
          result?.data?.RecurlyError?.message || result?.RecurlyError?.message
        );
      }

      if (result?.threeDSActionRequired && result?.actionTokenId) {
        setRecurlyToken(token);
        setThreeDSToken(result.actionTokenId);
        return;
      }
      if (result?.tokenId) {
        if (postCheckoutCallback) {
          await postCheckoutCallback(
            {
              planId: plan?.id,
              planCurrency: plan?.currency,
              couponCodes: getCouponCodeForPurchase(
                userCouponCodesStrings,
                hasAccountDiscount,
                true
              ),
              addOns: addOnsCodes,
            },
            trackingData
          );
        }

        optInCpa(true);

        setPreventLeave(false);

        if (successCallback) {
          successCallback();
        }
      }

      setFormSubmissionState({
        isSubmitted: true,
        isSubmitting: false,
      });

      if (result?.tokenId) {
        // to render <SuccessPage /> on separate route
        navigateToCompletePage?.(isDirectDebit);
      }
    } catch (e) {
      failurePurchaseAction();
    }
  };

  const removeInvalidCardError = useCallback(
    (field) => {
      setCardDetailError((values) => _omit(values, field));
    },
    [setCardDetailError]
  );

  const tokenHandler =
    (failedCallback?: () => void) =>
    async (err: RecurlyError | null, token: TokenPayload) => {
      if (err) {
        setFormSubmissionState({
          isSubmitted: true,
          isSubmitting: false,
        });
        if (err.code !== "validation") {
          // card/debit form validation errors scroll to form element instead of showing pop up
          setRecurlyErrorMsg(err.message || genericErrorMsg);
        }
        setCardDetailError(
          getInvalidCardFields(
            err.fields || [],
            {
              ...paymentDetailForm,
              ...directDebitForm,
              zipLabel: billingForm.zipLabel,
            },
            validation
          )
        );

        failedCallback && failedCallback();
      } else {
        await purchase(token.id);
      }
    };

  const enableExistingPaymentMethod =
    checkoutFlow === CheckoutFlow.AccountSettings ||
    checkoutFlow === CheckoutFlow.Default ||
    checkoutFlow === CheckoutFlow.GraduateTransfer;

  const submitForm = async (e?: SyntheticEvent<any>) => {
    setIsFailed(false);
    setFormSubmissionState({
      isSubmitted: true,
      isSubmitting: true,
    });

    e?.preventDefault();

    if (!isAnnual && isCC)
      return failurePurchaseAction(
        t("common:billing.checkout.errorMessage.monthlyCC")
      );

    if (useExistingPaymentMethod && enableExistingPaymentMethod) {
      setIsRenewalLoading(true);
      const planId = plan?.id ?? "";
      const couponCodes = getCouponCodeForPurchase(
        userCouponCodesStrings,
        hasAccountDiscount,
        true
      );

      try {
        if (checkoutFlow !== CheckoutFlow.GraduateTransfer) {
          // Skip "purchase" preset endpoint for grads conversion; they only need the call to `complete` if they have existing payment method
          const renewalData =
            await billingService.renewSubscriptionWithExisting({
              planId,
              // @ts-ignore
              couponCodes, // safe to ignore because we're passing shouldReturnArray = true to getCouponCodeForPurchase
              addOns: addOnsCodes,
            });

          if (!renewalData.accountId) {
            setRenewalError(genericErrorMsg);
            return;
          }
        }

        if (postCheckoutCallback) {
          await postCheckoutCallback(
            {
              planId,
              planCurrency: plan?.currency,
              couponCodes,
              planTypeFilter,
              addOns: addOnsCodes,
            },
            trackingData
          );
        }

        setIsRenewalLoading(false);

        if (navigateToCompletePage) {
          navigateToCompletePage(false);
          return;
        } else {
          history.push("/myhome/account-settings");
        }
      } catch {
        setRenewalError(genericErrorMsg);
        return;
      }
    }

    if (!_isEmpty(validationMessages) || !_isEmpty(cardDetailError)) {
      setFormSubmissionState({
        isSubmitted: true,
        isSubmitting: false,
      });
      return;
    }

    if (isDirectDebit && !isDirectDebitPreview) {
      setIsDirectDebitPreview(true);
      return;
    }

    if (isDirectDebit && isDirectDebitPreview) {
      recurly.bankAccount.token(
        generateBillingInfo(formValues, isSepa),
        tokenHandler(() => {
          setIsDirectDebitPreview(false);
        })
      );

      return;
    }

    recurly.token(htmlFormRef.current as HTMLFormElement, tokenHandler());
  };

  const submitThreeDSecure = useCallback(
    async (threeDSToken: ITokenPayload) => {
      await purchase(recurlyToken, threeDSToken.id);
    },
    [recurlyToken, purchase]
  );

  const handlePlanSelect = (
    paymentMethod: PlanCodeMethod,
    planPeriod: PlanCodePeriod
  ) => {
    const selectedPlan = subscriptions.find(
      (sub) =>
        sub.code.includes(planPeriod) &&
        checkPaymentMethod(paymentMethod, sub.code)
    );

    if (selectedPlan) {
      setPlan(selectedPlan);

      setIsFormOpen(true);
    }
  };

  useEffect(() => {
    if (isMobileView && isFormOpen) {
      scrollToFormSection();
    }
  }, [isFormOpen, paymentMethod]);

  const handleChangePlanMethod = (paymentMethodType: PlanCodeMethod) => {
    setPaymentMethod(paymentMethodType);
    setFormSubmissionState({
      isSubmitted: false,
      isSubmitting: false,
    });
    setMultipleFields(directDebitInitialValues);

    handlePlanSelect(paymentMethodType, planPeriodData.selectedPeriod!);
  };

  const handleBackButton = () => {
    if (isECPModalVariantFlagActive)
      setPlanPeriodData({ selectedPeriod: undefined, selectedAddOns: [] });
    return goBack && goBack();
  };

  const handleContinueButton = (e: SyntheticEvent<Element, Event>) => {
    return submitForm(e);
  };

  const directDebitPreviewOnEdit = () => {
    setFormSubmissionState({
      isSubmitted: true,
      isSubmitting: false,
    });
    setIsDirectDebitPreview(false);
  };

  const getBackBtnText = () => {
    if (isMobileView && isFormOpen) {
      return common.backMobile;
    }

    if (goBackText) {
      return goBackText;
    }

    return common.back;
  };

  const getCheckoutBtnText = () => {
    if (useExistingPaymentMethod) {
      return t("common:button.label.continue");
    }

    if (checkoutText) {
      return checkoutText;
    }

    return common.submit;
  };

  if (isRenewalLoading) return <Spinner />;

  if (renewalError) {
    return (
      <CommonErrorPopup
        errorStatusCode={400}
        popupProps={{
          close: () => history.go(0),
        }}
        alertMessageProps={{
          buttons: [
            {
              name: t("common:button.label.close"),
              type: "primary",
              click: () => history.go(0),
            },
          ],
        }}
      />
    );
  }

  if (threeDSToken && !isFailed)
    return (
      <ThreeDSecurePage
        threeDSToken={threeDSToken}
        submitThreeDSecure={submitThreeDSecure}
        onThreeDSecureError={failurePurchaseAction}
        threeDSClassName="h-[800px] m-auto w-full md:w-[400px]"
      />
    );
  if (isDirectDebitPreview && !isFailed)
    return (
      <DirectDebitConfirmPage
        name={`${summary.firstName} ${summary.lastName}`}
        email={summary.email}
        accountName={formValues[FieldNames.accountName]}
        sortCode={formValues[FieldNames.sortCode]}
        accountNumber={
          formValues[FieldNames.accountNumber] || formValues[FieldNames.iban]
        }
        directDebitPreviewOnEdit={directDebitPreviewOnEdit}
        submitForm={submitForm}
        setPreventLeave={setPreventLeave}
        planType={plan?.planType}
      />
    );

  const planName = t("common:billing.checkout.planName", {
    user: billingUserMap[billingUserType],
    planPeriod: planPeriodData.selectedPeriod,
  });

  return (
    <>
      <header>
        <PageTitle titleText={t("common:billing.checkoutStep.pageTitle")} />
      </header>
      <div className="flex flex-col flex-1" id={TOOLTIP_CONTAINER}>
        {!!recurlyErrorMsg && (
          <PaymentErrorModal
            handleCloseModal={() => {
              setRecurlyErrorMsg("");
            }}
            title={t("common:billing.errorPopup.title")}
            description={recurlyErrorMsg}
          />
        )}
        {!isMobileView && (
          <PriceInfo
            triggerRefetch={refetchPrices}
            addOns={planPeriodData.selectedAddOns}
            planName={planName}
            currencySymbol={currencySymbol}
            placeholderSubscription={placeholderSubscription}
            isAnnual={isAnnual}
          />
        )}
        <div className={formWrapperClassName}>
          <SummaryBlock
            planName={planName}
            price={price}
            couponBag={couponBag}
            isMobileView={isMobileView}
            disclaimerMessage="common:billing.checkout.orderSummary.continuousAuthorityAgreement"
          />
          <BillingAndPaymentForm
            isFormOpen={isFormOpen}
            formErrors={formErrors}
            submitForm={submitForm}
            billingFormRef={htmlFormRef}
            sectionRef={sectionRef}
            setFieldValue={setFieldValue}
            isSubmitted={formSubmissionState.isSubmitted}
            tootlipContainerConst={TOOLTIP_CONTAINER}
            formValues={formValues}
            cardDetailError={cardDetailError}
            removeInvalidCardError={removeInvalidCardError}
            isMobileView={isMobileView}
            cardFormIsVisible={cardFormIsVisible}
            isDirectDebit={isDirectDebit}
            planType={plan?.planType}
            planPeriod={planPeriodData.selectedPeriod!}
            selectedMethod={paymentMethod}
            setPaymentMethod={handleChangePlanMethod}
            enableExistingPaymentMethod={enableExistingPaymentMethod}
          />
        </div>
        <StickyCTABar
          prev={{
            text: getBackBtnText(),
            onClick: handleBackButton,
          }}
          next={{
            text: getCheckoutBtnText(),
            onClick: handleContinueButton,
            disabled: isMobileView
              ? !plan || !!!paymentMethod || formSubmissionState.isSubmitting
              : !isFormOpen || formSubmissionState.isSubmitting,
          }}
          isSticky={!mobileKeyboardIsOpen}
          ctaBarAdditionalClassName="max-w-full self-end"
        />
      </div>
    </>
  );
};

export default () => {
  const [loaded] = useExternalScript(RECURLY_JS_URL);
  const { planPeriodData } = useContext(BillingContext);

  const { data: summary, isLoading: summaryLoading } = useGetSummary();
  const { data: subscriptions, isLoading: subscriptionLoading } =
    useGetSubscriptions();

  if (!Array.isArray(subscriptions)) return null;

  const placeholderSubscription = subscriptions.find(
    // plan is actually selected when a payment method is chosen; data is the same for each plan, just different IDs for each method
    ({ code }) => code.includes(planPeriodData.selectedPeriod || "") && code
  );

  if (!loaded || subscriptionLoading || summaryLoading) return <Spinner />;

  return (
    <RecurlyWrapper>
      <AddOnCheckout
        subscriptions={subscriptions}
        placeholderSubscription={placeholderSubscription}
        summary={summary}
      />
    </RecurlyWrapper>
  );
};
